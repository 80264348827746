<template>
  <div class="table">
    <div class="container">
      <div class="handle-box">
        <el-input v-model="searchValus" placeholder="关键字" class="handle-input mr10"></el-input>
      </div>
      <div class="handle-box">
        <el-button type="primary" icon="search" @click="search">查询</el-button>
        <el-button type="primary" icon="delete" class="handle-del mr10" @click="handleAdd">新增</el-button>
      </div>
      <el-table class="z_default_table" :empty-text="emptyText" :row-style="rowStyle" v-loading="tableLoading"
        element-loading-text="拼命加载中" element-loading-background="rgba(0, 0, 0, 0.5)" :data="tableData" border
        style="width: 100%" ref="multipleTable" use-virtual height="2000">
        <el-table-column type="selection" align="center" width="40"></el-table-column>
        <el-table-column align="center" prop="userName" label="账号"></el-table-column>
        <el-table-column align="center" prop="nickName" label="呢称"></el-table-column>
        <el-table-column align="center" prop="phonenumber" label="手机号码">
        </el-table-column>
        <!-- <el-table-column align="center" prop="userType" label="用户类型">
          <template slot-scope="scope">
            <p v-if="scope.row.userType == '00'"> <span style="color: green"> 系统用户</span></p>
          </template>
</el-table-column> -->
        <el-table-column align="center" prop="roleId" label="角色" :formatter="roleFormatter">
        </el-table-column>
        <el-table-column align="center" prop="status" label="状态">
          <template slot-scope="scope">
            <p v-if="scope.row.status == 1"> <span style="color: green"> 正常</span></p>
            <p v-if="scope.row.status == 0"> <span style="color: red"> 停用</span></p>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" :width="120">
          <template slot-scope="scope">
            <el-button type="primary" size="small" @click="editRow(scope.row)">编辑</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageIndex"
          :page-sizes="pageSizes" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
          :total="tableTotal"></el-pagination>
      </div>
    </div>

    <!-- 新增弹出框 -->
    <el-dialog title="新增" :visible.sync="addVisible" width="40%" class="dialog-border-radius">
      <el-form ref="addForm" :rules="rules" :model="addForm" label-width="80px" :label-position="labelPosition"
        class="dialog-form">
        <el-form-item prop="userName" label="账号">
          <el-input v-model="addForm.userName"></el-input>
        </el-form-item>
        <el-form-item prop="nickName" label="昵称">
          <el-input v-model="addForm.nickName"></el-input>
        </el-form-item>
        <el-form-item prop="phonenumber" label="手机号码">
          <el-input v-model="addForm.phonenumber"></el-input>
        </el-form-item>
        <el-form-item prop="password" label="登录密码">
          <el-input v-model="addForm.password"></el-input>
        </el-form-item>
        <el-form-item prop="roleId" label="角色">
          <el-select v-model="addForm.roleId" placeholder="请选择" style="width: 100%" clearable>
            <el-option v-for="item in roleList" :key="item.id" :label="item.roleName" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="status" label="状态">
          <el-select v-model="addForm.status" placeholder="请选择" style="width: 100%">
            <el-option v-for="item in statusList" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="stationIds" label="关联驿站">
          <el-select v-model="addForm.stationIds" multiple placeholder="请选择" style="width: 100%">
            <el-option v-for="item in stationList" :key="`${item.id}`" :label="item.stationName" :value="`${item.id}`">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveAdd('addForm')">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="编辑" :visible.sync="editVisible" width="40%" class="dialog-border-radius">
      <el-form ref="form" :rules="rules" :model="form" label-width="80px" :label-position="labelPosition"
        class="dialog-form">
        <el-form-item prop="userName" label="账号">
          <el-input v-model="form.userName" disabled></el-input>
        </el-form-item>
        <el-form-item prop="nickName" label="昵称">
          <el-input v-model="form.nickName"></el-input>
        </el-form-item>
        <el-form-item prop="phonenumber" label="手机号码">
          <el-input v-model="form.phonenumber"></el-input>
        </el-form-item>
        <el-form-item label="登录密码">
          <el-input v-model="form.password"></el-input>
        </el-form-item>
        <el-form-item prop="roleId" label="角色">
          <el-select v-model="form.roleId" placeholder="请选择" style="width: 100%" clearable><el-option
              v-for="item in roleList" :key="item.id" :label="item.roleName" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="status" label="状态">
          <el-select v-model="form.status" placeholder="请选择" style="width: 100%">
            <el-option v-for="item in statusList" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="stationIds" label="关联驿站">
          <el-select v-model="form.stationIds" multiple placeholder="请选择" style="width: 100%">
            <el-option v-for="item in stationList" :key="`${item.id}`" :label="item.stationName" :value="`${item.id}`">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveEdit('form')">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import global from "@/common/global";
import { decodeUserInfo, requestParams, isNotEmpty, isEmpty } from "@/common/tools";
export default {
  components: {},
  data() {
    return {
      userInfo: decodeUserInfo(sessionStorage.getItem("userInfo")),
      pageSize: global.pageSize,
      pageSizes: global.pageSizes,
      pageIndex: 1,
      beforeRow: null,
      tableData: [], //表格数据
      tableTotal: 0,
      searchValus: "",
      multipleSelection: [], //选中的行
      addVisible: false,
      addForm: {},
      resetVisible: false,
      labelPosition: "right",
      indexArr: [],
      tableLoading: false,
      rules: {
        userName: [{ required: true, message: "请输入账号", trigger: "blur" }],
        nickName: [{ required: true, message: "请输入昵称", trigger: "blur" }],
        // phonenumber: [{ required: true, message: "请输入手机号", trigger: "blur" }, { min: 11, max: 11, message: '手机号码格式不对', trigger: 'blur' }],
        password: [{ required: true, message: "请输入登录密码", trigger: "blur" }],
        roleId: [{ required: true, message: "请选择角色", trigger: "change" }]
      },
      statusList: [{ label: "启用", value: 1 }, { label: "禁用", value: 0 }],
      editVisible: false,
      form: {},
      roleList: [],
      stationList: []
    };
  },
  created() {
    this.getData();
    this.getRoleList();
    this.getStationList();
  },
  updated() {

  },
  computed: {

  },
  methods: {
    rowStyle({ row, rowIndex }) {
      for (var i in this.indexArr) {
        if (rowIndex == this.indexArr[i].index) {
          return "background:" + global.listColor + ";";
        }
      }
    },
    getStationList() {
      var data = {
        pageSize: 100000,
        pageIndex: this.pageIndex
      };
      this.$axios
        .post("/oa/station/list", this.$qs.stringify(data))
        .then(res => {
          // console.log(res);
          this.stationList = res.data.data.rows;
        })
    },
    handleAdd() {
      this.addVisible = true;
      this.addForm = { status: 1, ...this.addForm };
    },
    saveAdd(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          var data = requestParams({ ...this.addForm, stationIds: this.addForm.stationIds.join(","), }, false);

          let phonenumber = this.addForm.phonenumber;
          if (isNotEmpty(phonenumber) && (isNaN(phonenumber) || `${phonenumber}`.length != 11)) {
            this.$message.error("手机号格式不正确");
            return;
          }

          this.$axios
            .post("/oa/sysUser/saveSysUser", this.$qs.stringify(data))
            .then(({ data }) => {
              this.addVisible = false;
              //console.log(this.addForm);
              this.$message[data.code == 0 ? "success" : "error"](data.message);
              this.getData();
            });

        }
      });
    },
    // 分页导航
    handleCurrentChange(val) {
      this.pageIndex = val;
      this.getData();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getData();
    },
    getData() {
      this.tableLoading = true;
      var data = {
        pageSize: this.pageSize,
        pageIndex: this.pageIndex,
        checkStatus: this.checkStatusType,
        searchValus: this.searchValus
      };
      this.emptyText = " ";
      this.$axios
        .post("/oa/sysUser/list", this.$qs.stringify(data))
        .then(res => {
          console.log(res);
          this.tableData = res.data.data.rows;
          this.tableLoading = false;
          this.tableTotal = Number(res.data.data.total);
          this.emptyText = "暂无数据";
        })
        .catch(error => {
          this.emptyText = "暂无数据";
          this.tableLoading = false;
        });
    },
    getRoleList() {
      this.$axios
        .post("/oa/role/list", this.$qs.stringify({}))
        .then(res => {
          console.log(res);
          this.roleList = res.data.data.rows;
        })
        .catch(error => { });
    },
    editRow(row) {
      let stationList = this.stationList;
      let stationIdsArr = isEmpty(row.stationIds) ? [] : (row.stationIds || "").split(",");
      this.form = { ...row, password: "", stationIds: stationIdsArr.filter(item => stationList.some(listItem => listItem.id == item))};
      console.log(this.form);
      this.editVisible = true;
    },
    saveEdit(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          let phonenumber = this.form.phonenumber;

          if (isNotEmpty(phonenumber) && (isNaN(phonenumber) || `${phonenumber}`.length != 11)) {
            this.$message.error("手机号格式不正确");
            return;
          }

          let data = requestParams({
            userId: this.form.userId,
            phonenumber: this.form.phonenumber,
            nickName: this.form.nickName,
            password: this.form.password,
            status: this.form.status,
            stationIds: this.form.stationIds.join(","),
            roleId: this.form.roleId
          });
          this.$axios
            .post("/oa/sysUser/updateSysUser", this.$qs.stringify(data))
            .then(({ data }) => {
              this.editVisible = false;
              this.$message[data.code == 0 ? "success" : "error"](data.message);
              this.getData();
            });
        }
      });
    },
    search() {
      this.pageIndex = 1;
      //   alert(this.select_cate);
      this.getData();
    },
    roleFormatter(row, column, cellValue, index) {
      let rList = this.roleList.filter(item => item.id == cellValue);
      return isNotEmpty(rList) ? rList[0].roleName : "";
    }
  }
};
</script>

<style scoped>
.table {
  height: 100%;
}

.table,
.container {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;
}

.table .el-table {
  display: flex;
  flex-flow: column nowrap;
  flex: 1;
}

.handle-box {
  margin-bottom: 20px;
}

.handle-select {
  width: 120px;
}

.search_select {
  width: 160px !important;
}

.handle-input {
  width: 160px;
  display: inline-block;
}

.del-dialog-cnt {
  font-size: 16px;
  text-align: center;
}

.select_name {
  display: inline-block;
  font-size: 14px;
}

.select_container {
  display: inline-block;
}

.select_container .el-date-editor.el-input {
  width: 140px;
}

.brandMs p {
  text-align: left;
}
</style>